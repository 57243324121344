import * as React from "react";
import { styled, Theme } from "@mui/system";
import { LinearProgress, linearProgressClasses, Box } from "@mui/material";
import { LinearProgressProps } from "@mui/material/LinearProgress/LinearProgress";
import { useScreenOptions } from "~/features/screen";
import { fontFamilies, colors } from "~/theme";

const BorderLinearProgress = styled(LinearProgress)(
    ({ theme, barcolor }: { theme: Theme; barcolor?: string }) => ({
        height: 6,
        borderRadius: 10,
        [theme.breakpoints.up(768)]: {
            width: 214,
        },
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: colors.tundora,
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: barcolor || colors.dodgerBlue,
        },
    }),
) as React.ComponentType<
    LinearProgressProps & {
        barcolor?: string;
    }
>;

const Title = styled("p")({
    fontFamily: fontFamilies.hurme2,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 16,
    lineHeight: "24px",
    letterSpacing: 0.15,
    color: colors.white,
    margin: "8px 0px",
});
const ArtText = styled("p")({
    fontFamily: fontFamilies.hurme2,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 12,
    lineHeight: "15px",
    letterSpacing: 0.066,
    color: colors.white,
    margin: "8px 0px",
});
const SupplyText = styled("p")({
    fontFamily: fontFamilies.hurme2,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "24px",
    letterSpacing: 0.15,
    color: colors.white,
    margin: "8px 0px",
});

interface ArtProps {
    src: string;
    title: string;
    artValue: string;
    supplyInfo: string;
    progress: number;
    barcolor: string;
    type?: string;
    width?: string;
    height?: string;
}
export const Art: React.FC<ArtProps> = ({
    src,
    title,
    artValue,
    supplyInfo,
    progress,
    barcolor,
    type = "video/mp4",
    width = "206",
    height = "206",
}) => {
    const { isMobile } = useScreenOptions();

    return (
        <>
            <Box
                sx={
                    isMobile
                        ? {
                              display: "flex",
                              justifyContent: "center",
                              marginTop: 64,
                          }
                        : undefined
                }
            >
                <video width={width} height={height} muted autoPlay loop>
                    <source src={src} type={type} />
                </video>
            </Box>
            <Title>{title}</Title>
            <ArtText>{artValue}</ArtText>
            <SupplyText>{supplyInfo}</SupplyText>
            <BorderLinearProgress
                variant="determinate"
                value={progress}
                barcolor={barcolor}
            />
        </>
    );
};
