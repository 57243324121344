import * as React from "react";
import { styled } from "@mui/system";
import { Box, Container, Button, Grid, Typography } from "@mui/material";
import { BodyM } from "~/features/typography";
import { colors, fontFamilies, fonts } from "~/theme";
import RewardImage from "./reward.svg";
import GleamImage from "./gleam.svg";
import ClaimImage from "./claim.svg";
import { Art } from "./Art";

const arts = [
    {
        name: "art-1",
        data: [
            {
                name: "red_smokey_ball",
                src: "/video/red_smokey_ball.mp4",
                title: "Level 1",
                artValue: "Token Allocation: $ART 200",
                supplyInfo: "Supply: 3,500 (35%)",
                progress: 35,
                barcolor: colors.webOrange,
            },
            {
                name: "rings",
                src: "/video/rings.mp4",
                title: "Level 2",
                artValue: "Token Allocation: $ART 300",
                supplyInfo: "Supply: 2,500 (25%)",
                progress: 25,
                barcolor: colors.shockingPink,
            },
            {
                name: "wave_jelly",
                src: "/video/wave_jelly.mp4",
                title: "Level 3",
                artValue: "Token Allocation: $ART 500",
                supplyInfo: "Supply: 2,000 (20%)",
                progress: 20,
                barcolor: colors.darkBlue,
            },
        ],
    },
    {
        name: "art-2",
        data: [
            {
                name: "knots",
                src: "/video/knots.mp4",
                title: "Level 4",
                artValue: "Token Allocation: $ART 600",
                supplyInfo: "Supply: 1,200 (12%)",
                progress: 12,
                barcolor: colors.seance,
            },
            {
                name: "bubbles",
                src: "/video/bubbles.mp4",
                title: "Level 5",
                artValue: "Token Allocation: $ART 700",
                supplyInfo: "Supply: 600 (6%)",
                progress: 6,
                barcolor: colors.aquamarine,
            },
            {
                name: "diamond",
                src: "/video/diamond.mp4",
                title: "Level 6",
                artValue: "Token Allocation: $ART 1,500",
                supplyInfo: "Supply: 200 (2%)",
                progress: 2,
                barcolor: colors.malachite,
            },
        ],
    },
];

const workItems = [
    {
        WorkImage: () => <GleamImage style={{ margin: 0 }} />,
        title: "Gleam Link",
        description: "Complete tasks by March 20th",
    },
    {
        WorkImage: () => <ClaimImage style={{ margin: 0 }} />,
        title: "Claim NFT",
        description: "Mint your membership",
    },
    {
        WorkImage: () => <RewardImage style={{ margin: 0 }} />,
        title: "Reward",
        description: "Unlock your membership perks",
    },
];

const Wrapper = styled(Box)(({ theme }) => ({
    padding: "67px 0",
    "& H3": {
        marginTop: 40,
        color: colors.white,
        marginBottom: 40,
    },
    [theme.breakpoints.down(768)]: {
        "& p > span": {
            fontSize: "22px",
            lineHeight: "28px",
        },
        "& H3": {
            marginTop: 8,
            fontSize: "15px",
            lineHeight: "22px",
        },
    },
}));
const GridItem = styled(Grid)({
    marginBottom: "70px",
});
const GridHeading = styled(Grid)({
    marginBottom: "16px",
});
const WorkItemGrid = styled(Grid)(({ theme }) => ({
    marginBottom: "70px",
    [theme.breakpoints.down(768)]: {
        marginBottom: 0,
    },
}));
const Title = styled("p")(({ theme }) => ({
    fontFamily: fontFamilies.hurme2,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 48,
    lineHeight: "54px",
    display: "flex",
    alignItems: "flex-end",
    letterSpacing: 0.374,
    color: colors.white,
    [theme.breakpoints.down(768)]: {
        fontSize: 28,
    },
}));
const SingleCard = styled(Grid)(({ theme }) => ({
    "& img": {
        display: "block",
        width: "auto",
        marginBottom: 32,
        [theme.breakpoints.down(768)]: {
            marginBottom: 16,
        },
    },
    "& h3": {
        color: colors.white,
        marginTop: 0,
        marginBottom: 0,
        [theme.breakpoints.down(768)]: {
            fontSize: "20px",
            lineHeight: "24px",
        },
    },
    "& p span": {
        color: colors.white,
        [theme.breakpoints.down(768)]: {
            fontSize: "13px",
            lineHeight: "17px",
        },
    },
    "& svg": {
        marginBottom: 25,

        [theme.breakpoints.down(768)]: {
            marginTop: 50,
            marginBottom: 10,
        },
    },
}));
const SingleCardWorkItem = styled(SingleCard)(({ theme }) => ({
    marginBottom: 0,
    [theme.breakpoints.down(768)]: {
        marginBottom: 56,
    },
}));
const WorkItemTitle = styled(Typography)(({ theme }) => ({
    fontFamily: fontFamilies.hurme2,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 25,
    lineHeight: "32px",
    color: colors.white,
    [theme.breakpoints.down(768)]: {
        fontSize: 20,
        lineHeight: "24px",
    },
}));
const StyledButton = styled(Button)(({ theme }) => ({
    fontWeight: "normal",
    "& .MuiBox-root": {
        ...fonts.hx16Medium,
    },
    backgroundColor: colors.white,
    background: colors.white,
    color: colors.black,
    width: 222,
    borderRadius: 28,
    fontFamily: fontFamilies.hurme2,
    textTransform: "none",
    fontSize: 16,
    "&:hover": {
        outline: `2px solid ${colors.white}`,
        background: colors.black,
        color: colors.white,
    },
    [theme.breakpoints.down(768)]: {
        fontSize: 13,
        lineHeight: 17,
        height: 48,
        width: "100%",
        overflow: "hidden",
    },
}));

interface ArtWorldsProps {
    airDropLink: string;
}
export const ArtWorlds: React.FC<ArtWorldsProps> = ({ airDropLink }) => {
    const handleOnEnterAirdrop = () => {
        globalThis?.window.open(airDropLink, "_blank");
    };

    return (
        <Wrapper>
            <Container>
                <Grid container direction="row" spacing={2}>
                    <GridItem container item>
                        <GridHeading item>
                            <Title>The Art World&apos;s NFT</Title>
                        </GridHeading>
                        {arts.map((contents) => {
                            const { data, name } = contents;
                            return (
                                <GridItem key={name} item container>
                                    {data.map((art) => {
                                        const { ...restProps } = art;
                                        return (
                                            <SingleCard
                                                key={art.name}
                                                item
                                                md={3}
                                                sm={4}
                                                xs={12}
                                            >
                                                <Art {...restProps} />
                                            </SingleCard>
                                        );
                                    })}
                                </GridItem>
                            );
                        })}
                    </GridItem>
                </Grid>
                <Box style={{ paddingTop: "50px" }}>
                    <WorkItemGrid container item>
                        <GridHeading item>
                            <Title>How it works</Title>
                        </GridHeading>
                        <Grid
                            container
                            sx={{
                                justify: "space-between",
                            }}
                            alignItems="center"
                            direction="row"
                            component="div"
                        >
                            {workItems.map(
                                ({ WorkImage, title, description }) => {
                                    return (
                                        <SingleCardWorkItem
                                            md={4}
                                            sm={4}
                                            xs={12}
                                            container
                                            direction="row"
                                            alignItems="center"
                                            key={title}
                                            item
                                        >
                                            <Box>
                                                <WorkImage />
                                            </Box>
                                            <Box style={{ marginLeft: 24 }}>
                                                <WorkItemTitle>
                                                    {title}
                                                </WorkItemTitle>
                                                <BodyM>{description}</BodyM>
                                            </Box>
                                        </SingleCardWorkItem>
                                    );
                                },
                            )}
                        </Grid>
                    </WorkItemGrid>
                    <StyledButton
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleOnEnterAirdrop}
                        disableRipple
                    >
                        Enter Free Mint
                    </StyledButton>
                </Box>
            </Container>
        </Wrapper>
    );
};
