import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { colors } from "~/theme";
import BannerBackground from "./bannerBackground.png";
import FooterBackground from "./footer-background.png";
import { AirdropBanner } from "./AirdropBanner/AirdropBanner";
import { ArtWorlds } from "./ArtWorlds";
import { Roadmap } from "./Roadmap/Roadmap";
import { Investors } from "./Investors/Investors";
import { MembershipPerks } from "./MembershipPerks/MembershipPerks";
import { PoweredByArt } from "./PoweredByArt/PoweredByArt";

export const HomepageWrapper = styled(Box)(() => ({
    background: `${colors.black} url(${BannerBackground}) top right`,
    backgroundSize: "1532px auto",
    backgroundRepeat: "no-repeat",
    "@media (max-width: 767px)": {
        backgroundPosition: "76% -2%",
    },
}));

export const FooterWrapper = styled(Box)(() => ({
    background: `${colors.black} url(${FooterBackground})`,
    backgroundPosition: "0% 70%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "90%",
    "@media (max-width: 767px)": {
        backgroundSize: "220% 50%",
        backgroundPosition: "40% 80%",
    },
    "@media (min-width: 768px)": {
        backgroundSize: "190%",
        backgroundPosition: "50% 76%",
    },
    "@media (min-width: 938px)": {
        backgroundSize: "90%",
        backgroundPosition: "0% 70%",
    },
}));

export const countDownEndDate = new Date(Date.UTC(2022, 2, 20, 12));
export const airDropLink = "https://memberships.liveart.xyz/";
export const discordChannel = "https://discord.com/invite/dxJEznbvKp";

export function Homepage() {
    return (
        <HomepageWrapper>
            <AirdropBanner
                countDownEndDate={countDownEndDate}
                airDropLink={airDropLink}
            />
            <ArtWorlds airDropLink={airDropLink} />
            <MembershipPerks />
            <PoweredByArt />
        </HomepageWrapper>
    );
}

export type HomePageFooterWrapperProps = React.PropsWithChildren<{
    Footer: React.FC<{}>;
}>;

export const HomePageFooterWrapper: React.FC<HomePageFooterWrapperProps> = ({
    Footer,
}) => {
    return (
        <FooterWrapper>
            <Investors />
            <Roadmap discordChannel={discordChannel} />
            <Footer />
        </FooterWrapper>
    );
};
