import * as React from "react";
import { styled } from "@mui/system";
import { Box, Container, Grid } from "@mui/material";
import { useScreenOptions } from "~/features/screen";
import { colors, fontFamilies } from "~/theme";

const Wrapper = styled(Box)(({ theme }) => ({
    padding: "67px 0",
    "& H3": {
        marginTop: 40,
        color: colors.white,
        marginBottom: 40,
    },
    [theme.breakpoints.down(768)]: {
        "& p > span": {
            fontSize: "22px",
            lineHeight: "28px",
        },
        "& H3": {
            marginTop: 8,
            fontSize: "15px",
            lineHeight: "22px",
        },
    },
}));
const GridItem = styled(Grid)({
    marginBottom: "70px",
});
const GridHeading = styled(Grid)({
    marginBottom: "16px",
});
const Title = styled("p")(({ theme }) => ({
    fontFamily: fontFamilies.hurme2,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 48,
    lineHeight: "54px",
    display: "flex",
    alignItems: "flex-end",
    letterSpacing: 0.374,
    color: colors.white,
    [theme.breakpoints.down(768)]: {
        fontSize: 28,
    },
}));
const ArtImage = styled("video")({
    maxWidth: "499px",
    maxHeight: "367px",
    width: "100%",
    height: "100%",
});

export const PoweredByArt: React.FC = () => {
    const { isMobile } = useScreenOptions();

    return (
        <Wrapper>
            <Container>
                <GridItem
                    container
                    item
                    direction="row"
                    sx={{
                        justify: isMobile ? "center" : "space-between",
                    }}
                    alignItems="center"
                >
                    <GridHeading>
                        <Title>Powered by $ART</Title>
                    </GridHeading>
                    <Grid>
                        <ArtImage muted autoPlay loop>
                            <source
                                src="/video/LiveArtToken.mp4"
                                type="video/mp4"
                            />
                        </ArtImage>
                    </Grid>
                </GridItem>
            </Container>
        </Wrapper>
    );
};
