import TickIcon from "~/features/icon/tick.svg";

export const membershipTableContent = [
    {
        key: "governance",
        data: [
            {
                key: "governance-level",
                value: "Platform governance proposal",
            },
            {
                key: "governance-level-1",
                value: <TickIcon stroke="white" stroke-width="1.2" />,
            },
            {
                key: "governance-level-2",
                value: <TickIcon stroke="white" stroke-width="1.2" />,
            },
            {
                key: "governance-level-3",
                value: <TickIcon stroke="white" stroke-width="1.2" />,
            },
            {
                key: "governance-level-4",
                value: <TickIcon stroke="white" stroke-width="1.2" />,
            },
            {
                key: "governance-level-5",
                value: <TickIcon stroke="white" stroke-width="1.2" />,
            },
            {
                key: "governance-level-6",
                value: <TickIcon stroke="white" stroke-width="1.2" />,
            },
        ],
    },
    {
        key: "ai",
        data: [
            {
                key: "ai-level",
                value: "Proprietary AI Market Analytics for NFTs (upcoming)",
            },
            {
                key: "ai-level-1",
                value: null,
            },
            {
                key: "ai-level-2",
                value: <TickIcon stroke="white" stroke-width="1.2" />,
            },
            {
                key: "ai-level-3",
                value: <TickIcon stroke="white" stroke-width="1.2" />,
            },
            {
                key: "ai-level-4",
                value: <TickIcon stroke="white" stroke-width="1.2" />,
            },
            {
                key: "ai-level-5",
                value: <TickIcon stroke="white" stroke-width="1.2" />,
            },
            {
                key: "ai-level-6",
                value: <TickIcon stroke="white" stroke-width="1.2" />,
            },
        ],
    },
    {
        key: "exclusive",
        data: [
            {
                key: "exclusive-level",
                value: "Exclusive NFT Releases by World Recognized Artists",
            },
            {
                key: "exclusive-level-1",
                value: null,
            },
            {
                key: "exclusive-level-2",
                value: null,
            },
            {
                key: "exclusive-level-3",
                value: <TickIcon stroke="white" stroke-width="1.2" />,
            },
            {
                key: "exclusive-level-4",
                value: <TickIcon stroke="white" stroke-width="1.2" />,
            },
            {
                key: "exclusive-level-5",
                value: <TickIcon stroke="white" stroke-width="1.2" />,
            },
            {
                key: "exclusive-level-6",
                value: <TickIcon stroke="white" stroke-width="1.2" />,
            },
        ],
    },
    {
        key: "physical",
        data: [
            {
                key: "physical-level",
                value: "Limited edition physical works of art and collectibles",
            },
            {
                key: "physical-level-1",
                value: null,
            },
            {
                key: "physical-level-2",
                value: null,
            },
            {
                key: "physical-level-3",
                value: null,
            },
            {
                key: "physical-level-4",
                value: <TickIcon stroke="white" stroke-width="1.2" />,
            },
            {
                key: "physical-level-5",
                value: <TickIcon stroke="white" stroke-width="1.2" />,
            },
            {
                key: "physical-level-6",
                value: <TickIcon stroke="white" stroke-width="1.2" />,
            },
        ],
    },
    {
        key: "elixir",
        data: [
            {
                key: "elixir-level",
                value: "Elixir Airdrops and early access",
            },
            {
                key: "elixir-level-1",
                value: null,
            },
            {
                key: "elixir-level-2",
                value: null,
            },
            {
                key: "elixir-level-3",
                value: null,
            },
            {
                key: "elixir-level-4",
                value: null,
            },
            {
                key: "elixir-level-5",
                value: <TickIcon stroke="white" stroke-width="1.2" />,
            },
            {
                key: "elixir-level-6",
                value: <TickIcon stroke="white" stroke-width="1.2" />,
            },
        ],
    },
    {
        key: "collectors",
        data: [
            {
                key: "collectors-level",
                value: "Collectors club Discord channel",
            },
            {
                key: "collectors-level-1",
                value: null,
            },
            {
                key: "collectors-level-2",
                value: null,
            },
            {
                key: "collectors-level-3",
                value: null,
            },
            {
                key: "collectors-level-4",
                value: null,
            },
            {
                key: "collectors-level-5",
                value: <TickIcon stroke="white" stroke-width="1.2" />,
            },
            {
                key: "collectors-level-6",
                value: <TickIcon stroke="white" stroke-width="1.2" />,
            },
        ],
    },
    {
        key: "elite",
        data: [
            {
                key: "elite-Level",
                value: "Elite Level 6 membership events",
            },
            {
                key: "elite-level-1",
                value: null,
            },
            {
                key: "elite-level-2",
                value: null,
            },
            {
                key: "elite-level-3",
                value: null,
            },
            {
                key: "elite-level-4",
                value: null,
            },
            {
                key: "elite-level-5",
                value: null,
            },
            {
                key: "elite-level-6",
                value: <TickIcon stroke="white" stroke-width="1.2" />,
            },
        ],
    },
];
