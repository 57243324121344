import React from "react";
import { styled } from "@mui/system";
import { Box, Container, Button } from "@mui/material";
import { colors, fonts, fontFamilies } from "~/theme";
import { useCountdown } from "~/features/countdown";

const Wrapper = styled(Box)(({ theme }) => ({
    height: "750px",
    display: "flex",
    alignItems: "center",
    marginTop: "-40px",
    [theme.breakpoints.down(768)]: {
        paddingTop: "40%",
    },
}));
const StyledContainer = styled(Container)({
    display: "flex",
});
const BannerFrame = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(768)]: {
        width: "100%",
        "& p > span": {
            fontSize: 22,
            lineHeight: "28px",
        },
    },
}));
const Content = styled(Box)({
    marginTop: 32,
});
const LargeText = styled("p")(({ theme }) => ({
    fontFamily: fontFamilies.hurme2,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 72,
    lineHeight: "100%",
    letterSpacing: 0.374,
    color: colors.white,
    margin: "24px 0px",
    [theme.breakpoints.down(768)]: {
        fontSize: 32,
    },
}));
const ContentText = styled("p")(({ theme }) => ({
    fontFamily: fontFamilies.hurme2,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 25,
    color: colors.white,
    margin: 0,
    [theme.breakpoints.down(768)]: {
        fontSize: 18,
    },
}));
const StyledButton = styled(Button)(({ theme }) => ({
    fontWeight: "normal",
    "& .MuiBox-root": {
        ...fonts.hx16Medium,
    },
    backgroundColor: colors.white,
    background: colors.white,
    color: colors.black,
    width: 222,
    borderRadius: 28,
    marginTop: 24,
    fontFamily: fontFamilies.hurme2,
    textTransform: "none",
    fontSize: 16,
    "&:hover": {
        outline: `2px solid ${colors.white}`,
        background: colors.black,
        color: colors.white,
    },
    [theme.breakpoints.down(768)]: {
        marginTop: 40,
        fontSize: 13,
        lineHeight: 17,
        height: 48,
        width: "100%",
        overflow: "hidden",
    },
}));
const TimerTitle = styled("span")({
    fontFamily: fontFamilies.hurme2,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 16,
    lineHeight: "24px",
    letterSpacing: "0.15px",
    marginLeft: 3,
    marginRight: 24,
});
const Timer = styled("p")({
    color: colors.white,
    fontFamily: fontFamilies.hurme2,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 44,
    lineHeight: "54px",
    margin: 0,
});

interface AirdropBannerProps {
    countDownEndDate: Date;
    airDropLink: string;
}
export const AirdropBanner: React.FC<AirdropBannerProps> = ({
    countDownEndDate,
    airDropLink,
}) => {
    const time = useCountdown({
        endTime: countDownEndDate,
    });

    const handleOnEnterAirdrop = () => {
        globalThis?.window.open(airDropLink, "_blank");
    };

    const renderCountdown = () => {
        if (time.totalMilliseconds <= 0) {
            return null;
        }

        return (
            <Timer>
                {time.days}
                <TimerTitle>d</TimerTitle>
                {time.hours}
                <TimerTitle>h</TimerTitle>
                {time.minutes}
                <TimerTitle>m</TimerTitle>
                {time.seconds}
                <TimerTitle>s</TimerTitle>
            </Timer>
        );
    };

    return (
        <Wrapper>
            <StyledContainer>
                <BannerFrame>
                    {renderCountdown()}
                    <Content>
                        <LargeText>FREE MINT NFT</LargeText>
                        <ContentText>Free Membership</ContentText>
                        <ContentText>$ART in every NFT</ContentText>
                        <ContentText>Exclusive Access to Alpha</ContentText>
                        <StyledButton
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleOnEnterAirdrop}
                            disableRipple
                        >
                            Enter Free Mint
                        </StyledButton>
                    </Content>
                </BannerFrame>
            </StyledContainer>
        </Wrapper>
    );
};
