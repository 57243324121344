/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @next/next/no-img-element */
import * as React from "react";
import { styled } from "@mui/system";
import {
    Box,
    Container,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    useMediaQuery,
} from "@mui/material";
import { colors, fontFamilies } from "~/theme";
import { membershipTableContent } from "./membershipTableContent";
import RightArrow from "./right-arrow.png";
import LeftArrow from "./left-arrow.png";

const Wrapper = styled(Box)(({ theme }) => ({
    padding: "67px 0",
    "& H3": {
        marginTop: 40,
        color: colors.white,
        marginBottom: 40,
    },
    [theme.breakpoints.down(768)]: {
        "& p > span": {
            fontSize: "22px",
            lineHeight: "28px",
        },
        "& H3": {
            marginTop: 8,
            fontSize: "15px",
            lineHeight: "22px",
        },
    },
}));
const GridItem = styled(Grid)({
    marginBottom: "70px",
});
const GridHeading = styled(Grid)({
    marginBottom: "16px",
});
const Title = styled("p")(({ theme }) => ({
    fontFamily: fontFamilies.hurme2,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 48,
    lineHeight: "54px",
    display: "flex",
    alignItems: "flex-end",
    letterSpacing: 0.374,
    color: colors.white,
    marginBottom: 24,
    [theme.breakpoints.down(768)]: {
        fontSize: 28,
    },
}));
const Description = styled("p")(({ theme }) => ({
    fontFamily: fontFamilies.hurme2,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 22,
    lineHeight: "28px",
    color: colors.white,
    marginBottom: 32,
    [theme.breakpoints.down(768)]: {
        fontSize: 18,
    },
}));
const StyledContainer = styled("div")({
    position: "relative",
    width: "100%",
});
const TableContainer = styled("div")({
    overflowX: "auto",
    width: "100%",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
        display: "none",
    },
});
const TableTitle = styled(TableCell)(({ theme }) => ({
    fontFamily: fontFamilies.hurme2,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 25,
    lineHeight: "32px",
    color: colors.white,
    borderBottom: "none",
    minWidth: "100px",
    paddingLeft: 0,
    [theme.breakpoints.down(768)]: {
        fontSize: 13,
    },
}));
const TableHeader = styled(TableCell)(({ theme }) => ({
    fontFamily: fontFamilies.hurme2,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "24px",
    textAlign: "center",
    letterSpacing: 0.15,
    color: colors.white,
    borderBottom: "none",
    minWidth: "100px",
    paddingLeft: 0,
    [theme.breakpoints.down(768)]: {
        fontSize: 13,
    },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: fontFamilies.hurme2,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 16,
    lineHeight: "24px",
    letterSpacing: 0.15,
    color: colors.white,
    margin: "0px 0px",
    borderBottomColor: colors.cornFlowerBlue,
    minWidth: "160px",
    paddingLeft: 0,
    [theme.breakpoints.down(768)]: {
        fontSize: 13,
    },
}));
const TableCellBenefitTitle = styled(StyledTableCell)({
    textAlign: "center",
    paddingRight: 0,
    minWidth: "unset !important",
});
const LeftArrowImg = styled("img")({
    position: "absolute",
    top: "48%",
});
const RightArrowImg = styled("img")();

export const MembershipPerks: React.FC = () => {
    const isSmallScreen = useMediaQuery("(max-width:768px)");
    const tableRef = React.useRef<HTMLDivElement>(null);
    const [scrollValue, setScrollValue] = React.useState(0);

    React.useEffect(() => {
        if (!globalThis?.window) {
            return;
        }

        const scroll =
            globalThis?.window.document.querySelector("#table_container");

        if (!scroll) {
            return;
        }

        const onScrollHandler = () => {
            setScrollValue(scroll.scrollLeft);
        };

        scroll.addEventListener("scroll", onScrollHandler, { passive: true });

        // eslint-disable-next-line consistent-return, func-names
        return function () {
            scroll.removeEventListener("scroll", onScrollHandler);
        };
    }, []);

    const getClientWidth = () => {
        if (!globalThis?.window) {
            return 0;
        }

        return (
            globalThis?.window.document.getElementById("table_container")
                ?.clientWidth || 0
        );
    };

    const onScroll = (isLeft) => () => {
        if (tableRef?.current) {
            const left = isLeft
                ? -tableRef.current.scrollWidth
                : tableRef.current.scrollWidth;
            setScrollValue(left);
            tableRef.current.scrollTo({
                top: 0,
                left,
                behavior: "smooth",
            });
        }
    };

    const renderLeftArrow = () => {
        const scrollWidth = tableRef?.current
            ? tableRef.current.scrollWidth
            : 0;
        const clientWidth = getClientWidth();

        if (scrollValue >= (scrollWidth - clientWidth) / 2 && isSmallScreen) {
            return (
                <LeftArrowImg
                    src={LeftArrow}
                    alt="left-arrow"
                    onClick={onScroll(true)}
                />
            );
        }
        return null;
    };

    const renderRightArrow = () => {
        const clientWidth = getClientWidth();

        const scrollWidth = tableRef?.current
            ? tableRef.current.scrollWidth
            : 0;

        if (scrollValue <= (scrollWidth - clientWidth) / 2 && isSmallScreen) {
            return (
                <RightArrowImg
                    src={RightArrow}
                    alt="right-arrow"
                    onClick={onScroll(false)}
                />
            );
        }
        return null;
    };

    return (
        <Wrapper>
            <Container>
                <Grid container direction="row" spacing={2}>
                    <GridItem container item>
                        <GridHeading item>
                            <Title>Membership Perks</Title>
                            <Description>
                                Each Free Mint Includes a Random LiveArtX NFT
                                Membership Level
                            </Description>
                        </GridHeading>
                        <StyledContainer>
                            {renderLeftArrow()}
                            <TableContainer ref={tableRef} id="table_container">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableTitle>Benefits</TableTitle>
                                            <TableHeader>Level 1</TableHeader>
                                            <TableHeader>Level 2</TableHeader>
                                            <TableHeader>Level 3</TableHeader>
                                            <TableHeader>Level 4</TableHeader>
                                            <TableHeader>Level 5</TableHeader>
                                            <TableHeader>Level 6</TableHeader>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {membershipTableContent.map(
                                            (content) => {
                                                return (
                                                    <TableRow key={content.key}>
                                                        {content.data.map(
                                                            (
                                                                cellData,
                                                                index,
                                                            ) => {
                                                                const TableCellComponent =
                                                                    index === 0
                                                                        ? StyledTableCell
                                                                        : TableCellBenefitTitle;
                                                                return (
                                                                    <TableCellComponent
                                                                        key={`${cellData.key}`}
                                                                    >
                                                                        {
                                                                            cellData.value
                                                                        }
                                                                    </TableCellComponent>
                                                                );
                                                            },
                                                        )}
                                                    </TableRow>
                                                );
                                            },
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {renderRightArrow()}
                        </StyledContainer>
                    </GridItem>
                </Grid>
            </Container>
        </Wrapper>
    );
};
