import * as React from "react";
import { styled } from "@mui/system";
import {
    Box,
    Container as MuiContainer,
    Button,
    Grid,
    Typography,
} from "@mui/material";
import { H2 } from "~/features/typography";
import { colors, fontFamilies } from "~/theme";

const Text = styled(Typography)(() => ({
    color: colors.white,
    fontSize: "22px",
    fontFamily: fontFamilies.hurme2,
    fontStyle: "normal",
    lineHeight: "28px",
    letterSpacing: "0px",
    textAlign: "left",
    fontWeight: 400,
    marginTop: "11px",
}));

const ListItem = styled(Typography)(() => ({
    color: colors.white,
    fontSize: "16px",
    fontFamily: fontFamilies.hurme2,
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "left",
    fontWeight: 400,
}));
const DiscordContainer = styled("div")({
    width: "100%",
    textAlign: "center",
    paddingTop: "50px",
});

const Wrapper = styled(Box)(({ theme }) => ({
    padding: "67px 0px",
    "& H3": {
        marginTop: 40,
        color: colors.white,
        marginBottom: 40,
    },
    [theme.breakpoints.down(768)]: {
        "& p > span": {
            fontSize: "22px",
            lineHeight: "28px",
        },
        "& H3": {
            marginTop: 8,
            fontSize: "15px",
            lineHeight: "22px",
        },
    },
}));
const GridHeading = styled(Grid)({
    marginBottom: 40,
});
const Title = styled("p")(({ theme }) => ({
    fontFamily: fontFamilies.hurme2,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 48,
    lineHeight: "54px",
    display: "flex",
    alignItems: "flex-end",
    letterSpacing: 0.374,
    color: colors.white,
    [theme.breakpoints.down(768)]: {
        fontSize: 28,
    },
}));
const RoadMapContainer = styled("div")(({ theme }) => ({
    marginBottom: "70px",
    [theme.breakpoints.up(1485)]: {
        marginLeft: "-9%",
        marginRight: "-9%",
    },
}));
const StyledContainer = styled("div")({
    width: "100%",
    display: "flex",
    height: "300px",
});
const BottomContainerMixin = {
    marginTop: -1,
};
const SpacerContainerMixin = {
    height: 60,
};
const SpaceContainerContainerLargeMixin = {
    height: "100px",
};

const Item = styled("div")({
    flex: 1,
    borderTopStyle: "dashed",
    borderBottomStyle: "dashed",
    borderColor: colors.white,
    marginRight: 2,
    borderWidth: 1,
});
const HiddenItemMixin = {
    flex: 1,
    borderTopStyle: "none",
};
const HideBottomBorderMixin = {
    borderBottomStyle: "none",
};
const FLex2Mixin = {
    flex: "2 !important",
};
const ItemRightBorderMixin = {
    borderRightStyle: "dashed",
    borderTopRightRadius: "40px",
    borderBottomRightRadius: "40px",
};
const ItemLeftBorderMixin = {
    borderLeftStyle: "dashed",
    borderTopLeftRadius: "40px",
    borderBottomLeftRadius: "40px",
};
const Flex3Mixin = {
    flex: "3 !important",
};
const HideTopBorderMixin = {
    borderTopStyle: "none",
};
const FLex5Mixin = {
    flex: "5 !important",
};
const NoBorderMixin = {
    borderStyle: "none",
};
const LastItemMixin = {
    borderLeftStyle: "dashed",
    borderTopLeftRadius: "40px",
};

const StyledButton = styled(Button)(({ theme }) => ({
    background: colors.white,
    color: colors.black,
    width: 184,
    height: 56,
    borderRadius: 100,
    marginTop: 40,
    fontFamily: fontFamilies.hurme2,
    textTransform: "none",
    fontSize: 16,
    "&:hover": {
        outline: `2px solid ${colors.white}`,
        background: colors.black,
        color: colors.white,
    },
    [theme.breakpoints.down(768)]: {
        marginTop: 40,
        fontSize: 13,
        lineHeight: 17,
        height: 48,
        width: "100%",
        overflow: "hidden",
    },
}));
const ItemIndicator = styled("div")({
    width: "12px",
    height: "12px",
    backgroundColor: colors.white,
    borderRadius: "50%",
    position: "relative",
    top: "-4px",
    left: "-6px",
});
const ListStyle = styled("ul")({
    padding: "0px",
    paddingLeft: "14px",
    color: colors.white,
    width: 207,
});

interface RoadmapDesktopProps {
    onJoinDiscord: () => void;
}
export const RoadmapDesktop: React.FC<RoadmapDesktopProps> = ({
    onJoinDiscord,
}) => {
    return (
        <Wrapper>
            <MuiContainer>
                <GridHeading item>
                    <Title>Roadmap</Title>
                </GridHeading>
                <RoadMapContainer>
                    <StyledContainer>
                        <Item
                            sx={{
                                ...HiddenItemMixin,
                                ...HideBottomBorderMixin,
                            }}
                        />
                        <Item sx={FLex2Mixin}>
                            <ItemIndicator />
                            <Text>January 2022</Text>
                            <ListStyle>
                                <li>
                                    <ListItem>NFT Marketplace on ETH</ListItem>
                                </li>
                            </ListStyle>
                        </Item>
                        <Item sx={FLex2Mixin}>
                            <ItemIndicator />
                            <Text>February 2022</Text>
                            <ListStyle>
                                <li>
                                    <ListItem>Secondary Market</ListItem>
                                </li>
                                <li>
                                    <ListItem>Buy Now</ListItem>
                                </li>
                            </ListStyle>{" "}
                        </Item>
                        <Item sx={FLex2Mixin}>
                            <ItemIndicator />
                            <Text>March 2022</Text>
                            <ListStyle>
                                <li>
                                    <ListItem>
                                        NFT membership Free Mint & $ART token
                                        launch
                                    </ListItem>
                                </li>
                            </ListStyle>
                        </Item>
                        <Item sx={FLex2Mixin}>
                            <ItemIndicator />
                            <Text>April-June 2022</Text>
                            <ListStyle>
                                <li>
                                    <ListItem>
                                        LiveArtX DAO with Membership v3
                                    </ListItem>
                                </li>
                                <li>
                                    <ListItem>
                                        NFT drops & exclusive event
                                    </ListItem>
                                </li>
                            </ListStyle>
                        </Item>
                        <Item sx={ItemRightBorderMixin} />
                    </StyledContainer>
                    <StyledContainer
                        sx={{
                            ...BottomContainerMixin,
                            ...SpacerContainerMixin,
                        }}
                    >
                        <Item sx={ItemLeftBorderMixin} />
                        <Item
                            sx={{
                                ...Flex3Mixin,
                                ...HideTopBorderMixin,
                            }}
                        />
                        <Item
                            sx={{
                                ...Flex3Mixin,
                                ...HideTopBorderMixin,
                            }}
                        />
                        <Item
                            sx={{
                                ...Flex3Mixin,
                                ...HideTopBorderMixin,
                                ...HideBottomBorderMixin,
                            }}
                        />
                    </StyledContainer>
                    <StyledContainer sx={BottomContainerMixin}>
                        <Item
                            sx={{
                                ...HideTopBorderMixin,
                                ...HiddenItemMixin,
                                ...HideBottomBorderMixin,
                            }}
                        />
                        <Item
                            sx={{
                                ...Flex3Mixin,
                                ...HideTopBorderMixin,
                                ...HideBottomBorderMixin,
                            }}
                        >
                            <ItemIndicator />
                            <Text>June-August 2022</Text>
                            <ListStyle>
                                <li>
                                    <ListItem>
                                        SaaS White-label Solution for Galleries
                                    </ListItem>
                                </li>
                                <li>
                                    <ListItem>Creator Hub launch</ListItem>
                                </li>
                                <li>
                                    <ListItem>Earning $ART interest</ListItem>
                                </li>
                            </ListStyle>
                        </Item>
                        <Item
                            sx={{
                                ...Flex3Mixin,
                                ...HideTopBorderMixin,
                                ...HideBottomBorderMixin,
                            }}
                        >
                            <ItemIndicator />
                            <Text>September/October 2022</Text>
                            <ListStyle>
                                <li>
                                    <ListItem>
                                        Developer Lab launch - bug bounties &
                                        open source
                                    </ListItem>
                                </li>
                            </ListStyle>
                        </Item>
                        <Item
                            sx={{
                                ...Flex3Mixin,
                                ...ItemRightBorderMixin,
                            }}
                        >
                            <ItemIndicator />
                            <Text>November/December 2022</Text>
                            <ListStyle>
                                <li>
                                    <ListItem>
                                        First major event - level 6 exclusive
                                    </ListItem>
                                </li>
                            </ListStyle>
                        </Item>
                    </StyledContainer>
                    <StyledContainer
                        sx={{
                            ...BottomContainerMixin,
                            ...SpaceContainerContainerLargeMixin,
                        }}
                    >
                        <Item
                            sx={{
                                ...FLex5Mixin,
                                ...NoBorderMixin,
                            }}
                        />
                        <Item
                            sx={{
                                ...FLex2Mixin,
                                ...LastItemMixin,
                                ...HideBottomBorderMixin,
                            }}
                        />
                        <Item
                            sx={{
                                ...Flex3Mixin,
                                ...NoBorderMixin,
                            }}
                        />
                    </StyledContainer>
                    <DiscordContainer>
                        <H2 color={colors.white}>Stay Updated</H2>
                        <StyledButton onClick={onJoinDiscord}>
                            Join Discord
                        </StyledButton>
                    </DiscordContainer>
                </RoadMapContainer>
            </MuiContainer>
        </Wrapper>
    );
};
