import * as React from "react";
import { styled } from "@mui/system";
import { Box, Container, Grid } from "@mui/material";
import { useScreenOptions } from "~/features/screen";
import { colors, fontFamilies } from "~/theme";
import AnimocaBrands from "./animoca_brands.png";
import BnbChain from "./bnb_chain.png";
import KucoinLabs from "./kucoin_labs.png";
import EverestVenturesGroup from "./everest_ventures_group.png";
import ArkStreamCapital from "./ark_stream_capital.png";
import Apenft from "./apenft.png";
import AlamedaResearch from "./alameda_research.png";
import AppWorks from "./app_works.png";
import BlackPine from "./black_pine.png";
import Gate from "./gate.png";
import Hashkey from "./hashkey.png";
import HeadAndShoulders from "./head_and_shoulders.png";
import OkxBlockdreamVentures from "./okx_blockdream_ventures.png";
import Snz from "./snz.png";
import ShimaCapital from "./shima_capital.png";
import TpsCapital from "./tps_capital.png";

const investorLogos = [
    {
        key: "animoca_brands",
        img: AnimocaBrands,
    },
    {
        key: "bnb_chain",
        img: BnbChain,
    },
    {
        key: "kucoin_labs",
        img: KucoinLabs,
    },
    {
        key: "everest_ventures_group",
        img: EverestVenturesGroup,
    },
    {
        key: "ark_stream_capital",
        img: ArkStreamCapital,
    },
    {
        key: "apenft",
        img: Apenft,
    },
    {
        key: "alameda_research",
        img: AlamedaResearch,
    },
    {
        key: "app_works",
        img: AppWorks,
    },
    {
        key: "black_pine",
        img: BlackPine,
    },
    {
        key: "gate",
        img: Gate,
    },
    {
        key: "hashkey",
        img: Hashkey,
    },
    {
        key: "head_and_shoulders",
        img: HeadAndShoulders,
    },
    {
        key: "okx_blockdream_ventures",
        img: OkxBlockdreamVentures,
    },

    {
        key: "snz",
        img: Snz,
    },
    {
        key: "shima_capital",
        img: ShimaCapital,
    },
    {
        key: "tps_capital",
        img: TpsCapital,
    },
];

const Wrapper = styled(Box)(({ theme }) => ({
    padding: "67px 0",
    "& H3": {
        marginTop: 40,
        color: colors.white,
        marginBottom: 40,
    },
    [theme.breakpoints.down(768)]: {
        "& p > span": {
            fontSize: "22px",
            lineHeight: "28px",
        },
        "& H3": {
            marginTop: 8,
            fontSize: "15px",
            lineHeight: "22px",
        },
    },
}));
const GridItem = styled(Grid)({
    marginBottom: "70px",
});
const GridHeading = styled(Grid)({
    marginBottom: "16px",
});
const Title = styled("p")(({ theme }) => ({
    fontFamily: fontFamilies.hurme2,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 48,
    lineHeight: "54px",
    display: "flex",
    alignItems: "flex-end",
    letterSpacing: 0.374,
    color: colors.white,
    [theme.breakpoints.down(768)]: {
        fontSize: 28,
    },
}));

export const Investors: React.FC = () => {
    const { isDesktop } = useScreenOptions();

    return (
        <Wrapper>
            <Container>
                <Grid container direction="row" spacing={2}>
                    <GridItem container item>
                        <GridHeading item>
                            <Title>Investors</Title>
                        </GridHeading>
                        <Grid
                            container
                            sx={{
                                justify: isDesktop
                                    ? "flex-start"
                                    : "space-around",
                            }}
                        >
                            {investorLogos.map((logo) => {
                                return (
                                    <Grid
                                        key={`investor_${logo.key}`}
                                        xs={5}
                                        md={4}
                                        lg={3}
                                        xl={3}
                                        item
                                    >
                                        <img
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "contain",
                                            }}
                                            src={logo.img}
                                            alt={`${logo.key}`}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </GridItem>
                </Grid>
            </Container>
        </Wrapper>
    );
};
