import * as React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { RoadmapMobile } from "./RoadMapMobile";
import { RoadmapDesktop } from "./RoadMapDesktop";

interface RoadmapProps {
    discordChannel: string;
}

export const Roadmap: React.FC<RoadmapProps> = ({ discordChannel }) => {
    const isDesktop = useMediaQuery("(min-width:938px)");

    const onJoinDiscord = () => {
        globalThis?.window.open(discordChannel, "_blank");
    };

    if (isDesktop) {
        return <RoadmapDesktop onJoinDiscord={onJoinDiscord} />;
    }

    return <RoadmapMobile onJoinDiscord={onJoinDiscord} />;
};
