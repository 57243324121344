import * as React from "react";
import { styled } from "@mui/system";
import { Box, Container, Button, Grid } from "@mui/material";
import { colors, fontFamilies } from "~/theme";
import DownArrow from "./down-arrow.png";

const Wrapper = styled(Box)(({ theme }) => ({
    padding: "67px 0px",
    "& H3": {
        marginTop: 40,
        color: colors.white,
        marginBottom: 40,
    },
    [theme.breakpoints.down(768)]: {
        "& p > span": {
            fontSize: "22px",
            lineHeight: "28px",
        },
        "& H3": {
            marginTop: 8,
            fontSize: "15px",
            lineHeight: "22px",
        },
    },
}));
const GridHeading = styled(Grid)({
    marginBottom: 40,
});
const Title = styled("p")(({ theme }) => ({
    fontFamily: fontFamilies.hurme2,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 48,
    lineHeight: "54px",
    display: "flex",
    alignItems: "flex-end",
    letterSpacing: 0.374,
    color: colors.white,
    [theme.breakpoints.down(768)]: {
        fontSize: 28,
    },
}));
const GridItem = styled(Grid)({
    marginBottom: "70px",
    marginLeft: "-9%",
    marginRight: "-9%",
});
const ItemContainerSmall = styled("div")({
    borderLeftStyle: "dashed",
    borderLeftWidth: 1,
    borderLeftColor: colors.white,
    marginLeft: "12%",
});
const ContainerSmall = styled("div")({
    marginBottom: 70,
});
const SmallItemIndicator = styled("div")({
    width: "12px",
    height: "12px",
    backgroundColor: colors.white,
    borderRadius: "50%",
    marginLeft: -7,
});
const SmallTitle = styled("p")({
    color: colors.white,
    fontSize: "22px",
    fontFamily: fontFamilies.hurme2,
    fontStyle: "normal",
    lineHeight: "28px",
    letterSpacing: "0px",
    textAlign: "left",
    fontWeight: 400,
    marginTop: -16,
    marginLeft: 24,
});
const SmallListItem = styled("span")({
    color: colors.white,
    fontSize: "16px",
    fontFamily: fontFamilies.hurme2,
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "left",
    fontWeight: 400,
    marginTop: -16,
});
const SmallListStyle = styled("ul")({
    padding: "0px",
    paddingLeft: "14px",
    color: "white",
    marginTop: -16,
    marginLeft: 24,
});
const DownArrowImg = styled("img")({
    position: "relative",
    left: -8,
    top: 77,
    width: 15,
});
const DiscordContainer = styled("div")({
    width: "100%",
    textAlign: "center",
    paddingTop: "50px",
});
const StayUpdate = styled("p")(({ theme }) => ({
    fontFamily: fontFamilies.hurme2,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 28,
    lineHeight: "34px",
    letterSpacing: 0.374,
    color: colors.white,
    margin: 0,
    textAlign: "left",
    [theme.breakpoints.up(767)]: {
        textAlign: "center",
    },
}));
const StyledButton = styled(Button)(({ theme }) => ({
    background: colors.white,
    color: colors.black,
    width: 184,
    height: 56,
    borderRadius: 100,
    marginTop: 40,
    fontFamily: fontFamilies.hurme2,
    textTransform: "none",
    fontSize: 16,
    "&:hover": {
        outline: `2px solid ${colors.white}`,
        background: colors.black,
        color: colors.white,
    },
    [theme.breakpoints.down(768)]: {
        marginTop: 40,
        fontSize: 13,
        lineHeight: 17,
        height: 48,
        width: "100%",
        overflow: "hidden",
    },
}));

interface RoadmapMobileProps {
    onJoinDiscord: () => void;
}
export const RoadmapMobile: React.FC<RoadmapMobileProps> = ({
    onJoinDiscord,
}) => {
    return (
        <Wrapper>
            <Container>
                <GridHeading item>
                    <Title>Roadmap</Title>
                </GridHeading>
                <Grid container direction="column">
                    <GridItem container item>
                        <Grid item container direction="column">
                            <ItemContainerSmall>
                                <ContainerSmall>
                                    <SmallItemIndicator />
                                    <SmallTitle>January 2022</SmallTitle>
                                    <SmallListStyle>
                                        <li>
                                            <SmallListItem>
                                                NFT Marketplace on ETH
                                            </SmallListItem>
                                        </li>
                                    </SmallListStyle>
                                </ContainerSmall>
                                <ContainerSmall>
                                    <SmallItemIndicator />
                                    <SmallTitle>February 2022</SmallTitle>
                                    <SmallListStyle>
                                        <li>
                                            <SmallListItem>
                                                Secondary Market
                                            </SmallListItem>
                                        </li>
                                        <li>
                                            <SmallListItem>
                                                Buy Now
                                            </SmallListItem>
                                        </li>
                                    </SmallListStyle>
                                </ContainerSmall>
                                <ContainerSmall>
                                    <SmallItemIndicator />
                                    <SmallTitle>March 2022</SmallTitle>
                                    <SmallListStyle>
                                        <li>
                                            <SmallListItem>
                                                NFT membership Free Mint & $ART
                                                token launch
                                            </SmallListItem>
                                        </li>
                                    </SmallListStyle>
                                </ContainerSmall>
                                <ContainerSmall>
                                    <SmallItemIndicator />
                                    <SmallTitle>April-June 2022</SmallTitle>
                                    <SmallListStyle>
                                        <li>
                                            <SmallListItem>
                                                LiveArtX DAO with Membership v3
                                            </SmallListItem>
                                        </li>
                                        <li>
                                            <SmallListItem>
                                                NFT drops & exclusive event
                                            </SmallListItem>
                                        </li>
                                    </SmallListStyle>
                                </ContainerSmall>
                                <ContainerSmall>
                                    <SmallItemIndicator />
                                    <SmallTitle>June-August 2022</SmallTitle>
                                    <SmallListStyle>
                                        <li>
                                            <SmallListItem>
                                                SaaS White-label Solution for
                                                Galleries
                                            </SmallListItem>
                                        </li>
                                        <li>
                                            <SmallListItem>
                                                Creator Hub launch
                                            </SmallListItem>
                                        </li>
                                        <li>
                                            <SmallListItem>
                                                Earning $ART interest
                                            </SmallListItem>
                                        </li>
                                    </SmallListStyle>
                                </ContainerSmall>
                                <ContainerSmall>
                                    <SmallItemIndicator />
                                    <SmallTitle>
                                        September/October 2022
                                    </SmallTitle>
                                    <SmallListStyle>
                                        <li>
                                            <SmallListItem>
                                                Developer Lab launch - bug
                                                bounties & open source
                                            </SmallListItem>
                                        </li>
                                    </SmallListStyle>
                                </ContainerSmall>
                                <ContainerSmall>
                                    <SmallItemIndicator />
                                    <SmallTitle>
                                        November/December 2022
                                    </SmallTitle>
                                    <SmallListStyle>
                                        <li>
                                            <SmallListItem>
                                                First major event - level 6
                                                exclusive
                                            </SmallListItem>
                                        </li>
                                    </SmallListStyle>
                                    <DownArrowImg
                                        src={DownArrow}
                                        alt="down-arrow"
                                    />
                                </ContainerSmall>
                            </ItemContainerSmall>
                        </Grid>
                    </GridItem>
                </Grid>
                <DiscordContainer>
                    <StayUpdate>Stay Updated</StayUpdate>
                    <StyledButton onClick={onJoinDiscord}>
                        Join Discord
                    </StyledButton>
                </DiscordContainer>
            </Container>
        </Wrapper>
    );
};
